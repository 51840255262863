import React, {useEffect} from 'react'
import {Empty} from 'antd'
import axios from 'axios'
import {
  BlogControllerServiceProxy,
  UpdateBlogDto,
  Response,
} from 'app/services/service-proxies/service-proxies'
import {useNavigate} from 'react-router-dom'
import {UserModel} from 'app/modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'setup'
import {APP_ROLES} from 'setup/app-constants'
import './BlogPage.scss'
import {parse} from 'node-html-parser'
import Blogitem from './Blogitem'
import {useIsMobileDevice} from 'app/shared/hooks/useIsMobileDevice.hook'

const BlogCardMobile: React.FC<{
  title: string
  description: string
  mainImage: string
  goTo: Function
}> = ({title, description, mainImage, goTo}) => {
  const truncate = (str: string, length: number) => {
    return str.length > 50 ? str.substring(0, length) + '...' : str
  }

  return (
    <div className='my-5'>
      <div className='card shadow'>
        <img src={mainImage} className='card-img-top' alt='...' />
        <div className='card-body'>
          <h5 className='card-title fs-2x fw-boldest'>{title}</h5>
          <p className='card-text'>{truncate(description, 200)}</p>
          <a onClick={() => goTo()} className='btn btn-primary'>
            Continuar leyendo...
          </a>
        </div>
      </div>
    </div>
  )
}

const blogClient = new BlogControllerServiceProxy(undefined, axios)

export const BlogPage = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const navigate = useNavigate()
  const isMobile = useIsMobileDevice()
  const [blogs, setBlogs] = React.useState<any[]>([])

  const getBlogs = async () => {
    const resp = await blogClient.findAll()
    const items = isAuthorized?.roles?.includes(APP_ROLES.ADMIN)
      ? resp.data
      : resp.data.filter((e: any) => e.published)
    setBlogs(items)
  }

  const changeState = async (value: boolean, id: string) => {
    const data = new UpdateBlogDto()
    data.published = value
    data._id = id
    await blogClient.changeState(id, data)
    await getBlogs()
  }

  const getImageSource = (html: string) => {
    if (html) {
      const result = parse(html)?.querySelector('img')?.getAttribute('src')
      return result ? result : 'https://fakeimg.pl/640x360'
    }
    return ''
  }

  const goToDetails = (blog: any) => {
    navigate(`detail/${blog.slug}`)
  }

  React.useEffect(() => {
    getBlogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className='row'>
        <div className='col-xl-12'>
          <h1 className='text-white display-1 text-center text-capitalize job-list-title'>
            Últimos artículos, noticias y actualizaciones
          </h1>
        </div>
      </div>
      <div className='row'>
        {isAuthorized?.roles?.includes(APP_ROLES.ADMIN) && (
          <div className='col-12 d-flex justify-content-end'>
            <a
              onClick={() => navigate('/consejos-busqueda-empleo/create')}
              className='btn btn-light'
            >
              Nuevo
            </a>
          </div>
        )}
        <div className='col-12  d-flex justify-content-center'>
          {blogs.length === 0 && <Empty description='No hay Consejos para la Búsqueda de Empleo' />}
          {blogs.length > 0 && (
            <div className='w-75'>
              <div className='row'>
                {blogs.map((blog: any) => {
                  return isMobile ? (
                    <BlogCardMobile
                      title={blog.title}
                      description={blog.description}
                      mainImage={blog.image ? blog.image : getImageSource(blog.body)}
                      goTo={() => goToDetails(blog)}
                      key={blog._id}
                    />
                  ) : (
                    <div className='col-12 col-lg-6' key={blog._id}>
                      <Blogitem
                        title={blog.title}
                        date={blog.publishAt ? blog.publishAt : blog.createdAt}
                        description={blog.description}
                        image={blog.image ? blog.image : getImageSource(blog.body)}
                        goTo={() => goToDetails(blog)}
                        showEdit={isAuthorized?.roles?.includes(APP_ROLES.ADMIN)}
                        isPublished={blog.published}
                        changeState={(value: boolean) => changeState(value, blog._id)}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
