import { FC, useRef, useEffect, useState } from 'react'
import { shallowEqual, useSelector, connect, useDispatch, ConnectedProps } from 'react-redux'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import { RootState } from '../../../../setup'
import { useLocation, useNavigate } from 'react-router-dom'

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const { accessToken, user } = useSelector<RootState>(({ auth }) => auth, shallowEqual) as any
  const navigate = useNavigate()
  const location = useLocation()

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          dispatch(props.fulfillUser(user))
          if (user) navigate(location.pathname)
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken && !isTokenExpired(accessToken)) {
      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
      // navigate('/home')
    }
    // eslint-disable-next-line
  }, [])

  const isTokenExpired = (token: string) =>
    Date.now() >= JSON.parse(atob(token.split('.')[1])).exp * 1000

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
