/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {AutoComplete} from 'antd'
import {
  CategoriesControllerServiceProxy,
  CompanyControllerServiceProxy,
  GetCategoryDto,
  GetLocationDto,
  HireTypeControllerServiceProxy,
  JobControllerServiceProxy,
  LocationsControllerServiceProxy,
} from 'app/services/service-proxies/service-proxies'
import {Pagination} from 'app/shared/components/Pagination'
import axios from 'axios'
import {useFormik} from 'formik'
import moment from 'moment'
import React from 'react'
import {useSearchParams} from 'react-router-dom'
import {CardJob} from './CardJob'
import './JobList.scss'
import {Helmet} from 'react-helmet'
import {removeNullUndefined} from 'app/shared/utils/utils'

export interface IFilters {
  keyword?: string
  location?: string
  hyreType?: string
  category?: string
  postedBy?: string
  company?: string
  isActive?: boolean
  isRemote?: boolean
  plublishedDate?: string
}

const publihedDateOptions = [
  {
    label: 'Hoy',
    value: moment().startOf('day').toDate(),
  },
  {
    label: 'Esta semana',
    value: moment().startOf('week').toDate(),
  },
  {
    label: 'Este mes',
    value: moment().startOf('month').toDate(),
  },
]

export const JobPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const [categories, setCategories] = React.useState([])
  const [locations, setLocation] = React.useState([])
  const [companies, setCompanies] = React.useState<any[]>([])
  const [hyreTypes, setHyreTypes] = React.useState<any[]>([])
  const [jobs, setJobs] = React.useState<any[]>([])
  const [filters, setFilters] = React.useState<IFilters>({})
  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalRecords, setTotalRecords] = React.useState(0)
  const pageSize = 5

  const jobClient = new JobControllerServiceProxy(undefined, axios)
  const categoryClient = new CategoriesControllerServiceProxy(undefined, axios)
  const locationClient = new LocationsControllerServiceProxy(undefined, axios)
  const companyClient = new CompanyControllerServiceProxy(undefined, axios)
  const hireTypeClient = new HireTypeControllerServiceProxy(undefined, axios)

  function getUniqueElements(array: any, prop: any) {
    return array.filter((element: {[x: string]: any}, index: any, arr: any[]) => {
      return arr.findIndex((obj) => obj[prop] === element[prop]) === index
    })
  }

  const getJobs = (values?: IFilters) => {
    let request = filters

    if (values) {
      console.log('values', values)
      request.keyword = values.keyword
      request.location = values.location
      request.category = values.category
    }

    jobClient
      .findAll(
        form.values.plublishedDate,
        true,
        2,
        filters.isRemote,
        filters.company,
        undefined,
        filters.category,
        filters.hyreType,
        filters.location,
        request.keyword,
        pageSize,
        currentPage
      )
      .then((res: any) => {
        res.data.Items.forEach((user: any) => (user.created_at = new Date(user.created_at)))
        setJobs(res.data.Items)
        setTotalRecords(res.data.TotalCount)
      })
  }

  const getData = async () => {
    const [locations, categories, companies, hireTypes] = await Promise.all([
      locationClient.getAllWithoutPagination(),
      categoryClient.getAllWithoutPagination(),
      companyClient.getAllWithoutPagination(undefined),
      hireTypeClient.getAllWithoutPagination(),
    ])

    setLocation(locations.data || [])
    setCategories(categories.data || [])
    setCompanies(getUniqueElements(companies.data, 'name') || [])
    setHyreTypes(hireTypes.data || [])
  }

  React.useEffect(() => {
    getJobs()
  }, [currentPage])

  React.useEffect(() => {
    document.getElementById('kt_post')?.classList.remove('container')
    document.getElementById('kt_post')?.classList.add('container-fluid')
    return () => {
      document.getElementById('kt_post')?.classList.remove('container-fluid')
      document.getElementById('kt_post')?.classList.add('container')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialValues: IFilters = {
    keyword: '',
    location: '',
    hyreType: '',
    category: '',
    postedBy: '',
    company: '',
    plublishedDate: undefined,
    isActive: undefined,
    isRemote: undefined,
  }

  const form = useFormik<IFilters>({
    initialValues,
    onSubmit: (values) => {
      setFilters(values)
      let payload = removeNullUndefined(values)
      setSearchParams(payload)
      getJobs(values)
    },
  })

  const handlePublishDateChange = (e: any) => {
    const checkbox = e.target as HTMLInputElement
    var checkboxes = document.getElementsByName('plublishedDate')
    checkboxes.forEach((item: any) => {
      if (item !== checkbox) item.checked = false
    })
    setFilters({
      ...filters,
      [e.target.value]: e.target.checked,
    })
    form.setFieldValue('plublishedDate', e.target.value)
  }

  React.useEffect(() => {
    getData()
    const keyword = searchParams.get('keyword')
    const location = searchParams.get('location')
    const category = searchParams.get('category')

    let params = {} as any

    if (keyword) {
      form.setFieldValue('keyword', keyword)
      params = {...params, keyword}
    }

    if (location) {
      form.setFieldValue('location', location)
      params = {...params, location}
    }

    if (category) {
      form.setFieldValue('category', category)
      params = {...params, category}
    }

    getJobs({...form.values, ...params})
  }, [])

  return (
    <div>
      <Helmet>
        <title>
          Tu Empleo Ideal | {form.getFieldProps('keyword').value || 'Listado de vacantes'}
        </title>
      </Helmet>
      <div className='row'>
        <div className='col-xl-12'>
          <h1 className='text-white display-1 text-center text-capitalize job-list-title'>
            Consigue tu proximo empleo
          </h1>
        </div>
      </div>
      <div className='job-listing-area pt-120'>
        <div>
          <div className='row'>
            {/* Left content */}
            <div className='col-xl-3 col-lg-3 col-md-4'>
              <div className='row'>
                <div className='col-12'>
                  <div className='small-section-tittle2 mb-45'>
                    <div className='ion'>
                      {' '}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        width='20px'
                        height='12px'
                      >
                        <path
                          fillRule='evenodd'
                          fill='rgb(27, 207, 107)'
                          d='M7.778,12.000 L12.222,12.000 L12.222,10.000 L7.778,10.000 L7.778,12.000 ZM-0.000,-0.000 L-0.000,2.000 L20.000,2.000 L20.000,-0.000 L-0.000,-0.000 ZM3.333,7.000 L16.667,7.000 L16.667,5.000 L3.333,5.000 L3.333,7.000 Z'
                        />
                      </svg>
                    </div>
                    <h4>Filtrar vacantes</h4>
                  </div>
                </div>
              </div>
              {/* Job Category Listing start */}
              <div className='job-category-listing mb-50 rounded'>
                <form onSubmit={form.handleSubmit} autoComplete='off'>
                  <input type='submit' hidden />
                  <div className='single-listing'>
                    <div className='small-section-tittle2'>
                      <h4>Buscar palabras clave</h4>
                    </div>
                    <div className='mb-5'>
                      <input
                        {...form.getFieldProps('keyword')}
                        type='text'
                        className='form-control'
                        placeholder='ej: web desing'
                      />
                    </div>
                  </div>
                  <div className='single-listing'>
                    <div className='small-section-tittle2'>
                      <h4>Compañias</h4>
                    </div>
                    <div className='mb-5'>
                      <div className='mb-5'>
                        <AutoComplete
                          allowClear={true}
                          onChange={(e) => {
                            setFilters({...filters, company: e})
                          }}
                          value={filters.company}
                          onSelect={(value: any, option: any) => {
                            setFilters({...filters, company: option.label})
                            form.setFieldValue('company', value)
                          }}
                          options={companies.map((option: GetCategoryDto) => {
                            return {
                              value: option._id,
                              label: option.name,
                            }
                          })}
                          filterOption={(inputValue, option) =>
                            option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          }
                          dropdownClassName='border-0'
                          style={{width: '100%'}}
                        >
                          <input
                            type='text'
                            className='form-control font-weight-bold pl-2'
                            placeholder='Compañia'
                          />
                        </AutoComplete>
                      </div>
                    </div>
                  </div>
                  {/* single one */}
                  <div className='single-listing'>
                    <div className='small-section-tittle2'>
                      <h4>Categoría de vacante</h4>
                    </div>
                    {/* Select job items start */}
                    <div className='mb-5'>
                      <AutoComplete
                        value={filters.category}
                        onChange={(e) => {
                          setFilters({...filters, category: e})
                        }}
                        onSelect={(value: any, option: any) => {
                          setFilters({...filters, category: option.label})
                          form.setFieldValue('category', value)
                        }}
                        allowClear={true}
                        dropdownClassName='border-0'
                        style={{width: '100%'}}
                        options={categories.map((option: GetCategoryDto) => {
                          return {
                            value: option._id,
                            label: option.name,
                          }
                        })}
                        filterOption={(inputValue, option) =>
                          option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                      >
                        <input
                          type='text'
                          className='form-control font-weight-bold pl-2'
                          placeholder='Categoría'
                        />
                      </AutoComplete>
                    </div>
                    {/*  Select job items End*/}
                    {/* select-Categories start */}
                    <div className='select-Categories '>
                      <div className='small-section-tittle2'>
                        <h4>Tipo de empleo</h4>
                      </div>
                      {hyreTypes.map((hyreType: any) => (
                        <label className='container' key={hyreType._id}>
                          {hyreType.name}
                          <input
                            name='hyreType'
                            type='checkbox'
                            value={hyreType._id}
                            onChange={(e) => {
                              const checkbox = e.target as HTMLInputElement
                              var checkboxes = document.getElementsByName('hyreType')
                              checkboxes.forEach((item: any) => {
                                if (item !== checkbox) item.checked = false
                              })
                              setFilters({
                                ...filters,
                                [e.target.value]: e.target.checked,
                              })
                              form.setFieldValue('hyreType', e.target.value)
                            }}
                          />
                          <span className='checkmark' />
                        </label>
                      ))}
                    </div>
                    {/* select-Categories End */}
                  </div>
                  {/* single two */}
                  <div className='single-listing'>
                    <div className='small-section-tittle2'>
                      <h4>Locación de vacante</h4>
                    </div>
                    {/* Select job items start */}
                    <div className='mb-5'>
                      <AutoComplete
                        value={filters.location}
                        onChange={(e) => {
                          setFilters({...filters, location: e})
                        }}
                        onSelect={(value: any, option: any) => {
                          setFilters({...filters, location: option.label})
                          form.setFieldValue('location', value)
                        }}
                        allowClear={true}
                        style={{width: '100%'}}
                        options={locations.map((option: GetLocationDto) => {
                          return {
                            value: option._id,
                            label: option.name,
                          }
                        })}
                        filterOption={(inputValue, option) =>
                          option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                      >
                        <input
                          type='text'
                          className='form-control font-weight-bold pl-2'
                          placeholder='Locación'
                        />
                      </AutoComplete>
                    </div>
                    {/*  Select job items End*/}
                    {/* select-Categories start */}
                    {/* select-Categories End */}
                  </div>
                  {/* single three */}
                  <div className='single-listing'>
                    {/* select-Categories start */}
                    <div className='select-Categories pb-50'>
                      <div className='small-section-tittle2'>
                        <h4>Publicado Dentro</h4>
                      </div>
                      {publihedDateOptions.map((publihedDateOption: any) => (
                        <label className='container' key={publihedDateOption.label}>
                          {publihedDateOption.label}
                          <input
                            value={publihedDateOption.value}
                            type='checkbox'
                            name='plublishedDate'
                            onChange={handlePublishDateChange}
                          />
                          <span className='checkmark' />
                        </label>
                      ))}
                    </div>
                    {/* select-Categories End */}
                  </div>
                  <div className='single-listing'>
                    <div className='d-flex btn-group' role='group' aria-label='Basic example'>
                      <button type='submit' className='btn btn-primary'>
                        <i className='fas fa-search    '></i> buscar
                      </button>
                      <button
                        type='reset'
                        className='btn btn-secondary'
                        onClick={() => {
                          form.resetForm()
                          form.submitForm()
                          setFilters({})
                        }}
                      >
                        <i className='fas fa-eraser    '></i> Limpiar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {/* Job Category Listing End */}
            </div>
            {/* Right content */}
            <div className='col-xl-9 col-lg-9 col-md-8'>
              {/* Featured_job_start */}
              <section className='featured-job-area'>
                <div className='container'>
                  {/* Count of Job list Start */}
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='count-job mb-35'>
                        <span>{totalRecords} Vacantes encontradas</span>
                        {/* Select job items start */}
                        {/* <div className='row'>
                          <span className='col-sm-6 col-form-label'>Ordenar por</span>
                          <div className='col-sm-6'>
                            <select name='select' className='form-select'>
                              <option>None</option>
                              <option>job list</option>
                              <option>job list</option>
                              <option>job list</option>
                            </select>
                          </div>
                        </div> */}
                        {/*  Select job items End*/}
                      </div>
                    </div>
                  </div>
                  {/* Count of Job list End */}
                  {jobs.map((job: any) => (
                    <CardJob key={job._id} job={job} />
                  ))}
                </div>
              </section>
              {/* Featured_job_end */}
              <div className=' pb-115 text-center'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-xl-12'>
                      <div className='single-wrap d-flex justify-content-center'>
                        <Pagination
                          showCount={false}
                          pageSize={pageSize}
                          totalRecorsds={totalRecords}
                          currentPage={currentPage}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
