import React, {FC} from 'react'

export const FilterDropdown: FC = ({children}) => {
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Opciones de filtro</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5 card-scroll h-300px'>{children}</div>
    </div>
  )
}
