/* eslint-disable jsx-a11y/anchor-is-valid */
import {EmailInput, JobControllerServiceProxy} from 'app/services/service-proxies/service-proxies'
import {TimeAgo} from 'app/shared/components/TimeAgo'
import {Image} from 'antd'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import {
  emailLink,
  facebookLink,
  linkedinLink,
  twitterLink,
  whatsappLink,
} from 'app/shared/shareLinks'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import {APP_COMPANY_DEFAULT_LOGO, APP_ROLES} from 'setup/app-constants'
import {KTSVG, toAbsoluteUrl} from '_metronic/helpers'
import {CardJob} from './CardJob'
import clsx from 'clsx'
import {message} from 'antd'
import {UserModel} from 'app/modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'setup'
import './ViewJob.scss'
import {Helmet} from 'react-helmet'
import {useCountdown} from 'app/shared/hooks'
import {createWorker} from 'tesseract.js'

dayjs.extend(isBetween)

const initialValues = {
  name: '',
  message: '',
  attachment: null,
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('El nombre es requerido'),
  message: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(1000, 'Maximo 50 caracteres')
    .required('El mensaje es requerido'),
  attachment: Yup.mixed().required('Debe adjuntar un archivo'),
})

export const ViewJob = () => {
  const user = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const params = useParams()
  const navigate = useNavigate()
  const [job, setJob] = useState<any>()
  const [recentJobs, setRecentJobs] = useState([])
  const slug = params.slug!
  const [showModal, setShowModal] = useState(false)
  const [days, hours, minutes, seconds] = useCountdown(new Date(job?.publishedTo).getTime())
  const [jobDescription, setJobDescription] = useState<string>('')

  const jobClient = new JobControllerServiceProxy(undefined, axios)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      let input: EmailInput = new EmailInput()
      input.name = values.name
      input.subject = job.title
      input.email = job?.company?.email
      input.body = values.message
      input.attachments = values?.attachment!
      jobClient
        .apply(job._id, input)
        .then(() => {
          message.success('Su solicitud ha sido enviada')
          setShowModal(false)
          formik.resetForm()
        })
        .catch((err) => {
          setStatus(err.message)
        })
    },
  })

  useEffect(() => {
    getJob()
    jobClient.getRecentJobs(5).then((res) => {
      setRecentJobs(res.data)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  const getJob = async () => {
    const res = await jobClient.getbySlug(slug)
    setJob(res.data)
    recognize(res.data.image)
    if (!user) {
      await jobClient.addView(res.data._id)
    }
  }

  const share = (type: string, title: string) => {
    const url = `${window.location.origin}/ofertas-de-empleo/${slug}`

    switch (type) {
      case 'facebook':
        window.open(
          facebookLink(url, {}),
          '_blank',
          'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400'
        )
        break

      case 'linkedin':
        window.open(
          linkedinLink(url, {}),
          '_blank',
          'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400'
        )
        break

      case 'twitter':
        window.open(
          twitterLink(url, {}),
          '_blank',
          'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400'
        )
        break

      case 'whatsapp':
        window.open(
          whatsappLink(url, {title: title}),
          '_blank',
          'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400'
        )
        break

      case 'email':
        window.open(emailLink(url, {subject: title}))
        break

      default:
        break
    }
  }

  const hasTimeLeft = (job: any) => {
    if (job && job.publishedFrom && job.publishedTo) {
      return dayjs().isBetween(job.publishedFrom, dayjs(job.publishedTo))
    }
    return false
  }

  const validity = (job: any) => {
    if (job) {
      return job.isApproved && job.isActive && hasTimeLeft(job)
    }
    return true
  }

  const recognize = async (image: any) => {
    if (!image) return
    const worker = await createWorker()

    try {
      await worker.load()
      await worker.loadLanguage('eng+spa')
      await worker.initialize('eng+spa')

      const {
        data: {text},
      } = await worker.recognize(image)
      const textFormatted = formatText(text)
      setJobDescription(textFormatted)
    } catch (error) {
      console.error('Error al reconocer el texto:', error)
    } finally {
      await worker.terminate()
    }
  }

  const formatText = (text: string) => {
    text = text.replace(/[“”‘’"'–—]/g, '"')
    text = text.replace(/[\n\r]+/g, ' ')
    text = text.trim()

    const lineas = text.split('. ')
    const textoFormateado = lineas
      .map((line: string) => {
        return line.charAt(0).toUpperCase() + line.slice(1)
      })
      .join('. ')

    return textoFormateado
  }

  return (
    <div>
      <Helmet>
        <title>Tu Empleo Ideal | {job?.title || ''}</title>
        <meta
          name='description'
          content={
            job?.title
              ? `Únete a nuestro equipo en ${job?.company?.name} como ${job?.title}. 
        Impulsa tu carrera con un entorno innovador y desafiante liderado por un equipo dedicado hacia el éxito empresarial.`
              : ''
          }
        />
      </Helmet>
      <div
        id='kt_content_container'
        className='d-flex flex-column-fluid align-items-start container-xxl'
      >
        <div className='content flex-row-fluid' id='kt_content'>
          <div className='card card-flush'>
            <div className='card-body p-lg-17'>
              <div className='position-relative mb-17'>
                <a
                  style={{zIndex: 1000000000000}}
                  className='position-absolute mx-5 my-5 text-light'
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  <i className='fas fa-arrow-left'></i>
                </a>
                <div className='overlay overlay-show'>
                  <div
                    className='bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-250px'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl('/media/stock/1600x800/img-2.jpg')})`,
                    }}
                  />
                  <div className='overlay-layer rounded bg-black' style={{opacity: '0.4'}} />
                </div>
                <div className='position-absolute text-white mb-8 ms-10 bottom-0 w-100'>
                  <h3 className='text-white fs-2qx fw-bolder mb-3 m'>{job?.title}</h3>
                  <span className='badge badge-light me-5'>{job?.hyreType?.name}</span>
                  <span className='badge badge-light me-5'>
                    <i className='fas fa-map-marked-alt me-2'></i> {job?.location?.name}
                  </span>
                  <span className='badge badge-light me-5'>
                    <i className='fas fa-calendar-alt me-2'></i>
                    Publicado <TimeAgo time={job?.created_at} />
                  </span>
                  {user?.roles?.includes(APP_ROLES.ADMIN) && (
                    <>
                      <span className='badge badge-light me-5'>
                        <i className='fas fa-eye me-2'></i>
                        Visto {job?.viewCount}
                      </span>
                      <span className='badge badge-light me-5'>
                        <i className='fas fa-paper-plane me-2'></i>
                        Solicitudes enviadas {job?.requested}
                      </span>
                    </>
                  )}
                  {job?.publishedTo &&
                    (job?.postedBy?._id === user?._id ||
                      user?.roles?.includes(APP_ROLES.ADMIN)) && (
                      <>
                        <span
                          className={clsx(
                            'badge me-5',
                            {'badge-light': validity(job)},
                            {'badge-danger': !validity(job)}
                          )}
                        >
                          <i
                            className={clsx(
                              'me-2',
                              {'fas fa-check-square': validity(job)},
                              {'fas fa-times-circle text-white': !validity(job)}
                            )}
                          ></i>
                          {validity(job)
                            ? `Vigente: Quedan ${days} dias ${hours} horas ${minutes} minutos ${seconds} segundos`
                            : `Caducado: Hace ${Math.abs(days)} dias ${Math.abs(
                                hours
                              )} horas ${Math.abs(minutes)} minutos ${Math.abs(seconds)} segundos`}
                        </span>
                      </>
                    )}
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row mb-17'>
                <div className='flex-lg-row-fluid me-0 me-lg-20'>
                  <div className='mb-17'>
                    <div className='m-0'>
                      <div className='d-md-flex'>
                        <div className='symbol symbol-100px me-10 mb-10'>
                          <img
                            src={
                              job?.company?.logoUrl
                                ? job?.company?.logoUrl
                                : APP_COMPANY_DEFAULT_LOGO.url
                            }
                            alt=''
                          />
                        </div>
                        <div className='ms-10 bottom-0'>
                          <p className='display-6 mb-0'>{job?.company?.name}</p>
                          {job?.company?.url && (
                            <span className='me-5'>
                              <i className='fas fa-link me-2'></i>
                              <a href={job?.company?.url} target='_blank' rel='noreferrer'>
                                {job?.company?.url}
                              </a>
                            </span>
                          )}
                          <span>
                            <i className='fas fa-envelope me-2'></i>
                            <a
                              className='btn btn-link btn-color-primary text-decoration-underline'
                              href={`mailto:${
                                job?.emails.length > 0 ? job.emails.join(', ') : job?.company?.email
                              }`}
                              rel='noopener noreferrer'
                            >
                              {job?.emails.length > 0 ? job.emails.join(', ') : job?.company?.email}
                            </a>
                          </span>
                        </div>
                      </div>
                      <div className='separator my-5'></div>
                      <h4 className='fs-1 text-gray-800 w-bolder mb-6'>
                        Descripción de la vacante
                      </h4>
                      {!job?.onlyImage && (
                        <div
                          className='job-description'
                          dangerouslySetInnerHTML={{__html: job?.description}}
                        />
                      )}
                      {job?.onlyImage && (
                        <div className='image-container'>
                          <Image width={500} src={job.image} />
                          <div className='d-none'>{jobDescription}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='flex-lg-row-auto w-100 w-lg-275px w-xxl-350px'>
                  {!user && job?.contactMethod?.includes('EMAIL') && (
                    <div style={{height: '5rem'}} className='d-grid gap-2 d-md-block mb-5'>
                      <button
                        onClick={() => setShowModal(true)}
                        type='button'
                        style={{borderRadius: '2rem', width: '100%', height: '100%'}}
                        className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary'
                        disabled={job?.emails.length === 0 && !job?.company?.email}
                      >
                        Solicitar trabajo <i className='fas fa-paper-plane text-primary'></i>
                      </button>
                    </div>
                  )}
                  {!user && job?.contactMethod?.includes('WEBSITE') && (
                    <div style={{height: '5rem'}} className='d-grid gap-2 d-md-block mb-5'>
                      <a
                        type='button'
                        style={{borderRadius: '2rem', width: '100%', height: '100%'}}
                        className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary d-flex justify-content-center align-items-center'
                        href={job?.linkToApply}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Solicitar trabajo <i className='fas fa-paper-plane text-primary ms-2'></i>
                      </a>
                    </div>
                  )}
                  <div className='card bg-light'>
                    <div className='card-body'>
                      <div className='mb-7'>
                        <h2 className='fs-1 text-gray-800 w-bolder mb-6 ml-15'>Otros datos</h2>
                        <p className='d-flex ml-15 fw-bold fs-6 text-gray-600'>
                          <KTSVG
                            path='/media/icons/duotune/ecommerce/ecm009.svg'
                            className='svg-icon svg-icon-2hx svg-icon-info me-1'
                          />
                          <span>{job?.category?.name}</span>
                        </p>
                        {job?.phone && (
                          <p className='d-flex ml-15 fw-bold fs-6 text-gray-600'>
                            <KTSVG
                              path='/media/icons/duotune/communication/com005.svg'
                              className='svg-icon svg-icon-2hx svg-icon-info me-1'
                            />
                            <span>{job?.phone}</span>
                          </p>
                        )}
                        {job?.salary > 0 && (
                          <p className='d-flex ml-15 fw-bold fs-6 text-gray-600'>
                            <KTSVG
                              path='/media/icons/duotune/finance/fin010.svg'
                              className='svg-icon svg-icon-2hx svg-icon-info me-1'
                            />
                            <span>
                              {job?.salary?.toLocaleString('es-DO', {
                                style: 'currency',
                                currency: 'DOP',
                              })}
                            </span>
                          </p>
                        )}

                        <p className='d-flex ml-15 fw-bold fs-6 text-gray-600'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen014.svg'
                            className='svg-ico svg-icon-info me-1 svg-icon-2hx'
                          />
                          <span>{new Date(job?.created_at).toLocaleString()}</span>
                        </p>

                        <div className='d-flex justify-content-evenly'>
                          <div>
                            <FacebookShareButton
                              url={`${window.location.origin}/ofertas-de-empleo/${job?.slug}`}
                              quote={job?.title}
                              className='Demo__some-network__share-button'
                            >
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <div>
                              <FacebookShareCount
                                url={`${window.location.origin}/ofertas-de-empleo/${job?.slug}`}
                                className='Demo__some-network__share-count'
                              >
                                {(count) => count}
                              </FacebookShareCount>
                            </div>
                          </div>
                          <div>
                            <TwitterShareButton
                              url={`${window.location.origin}/ofertas-de-empleo/${job?.slug}`}
                              title={job?.title}
                              className='Demo__some-network__share-button'
                            >
                              <TwitterIcon size={32} round />
                            </TwitterShareButton>
                          </div>
                          <div>
                            <TelegramShareButton
                              url={`${window.location.origin}/ofertas-de-empleo/${job?.slug}`}
                              title={job?.title}
                              className='Demo__some-network__share-button'
                            >
                              <TelegramIcon size={32} round />
                            </TelegramShareButton>
                          </div>
                          <div>
                            <WhatsappShareButton
                              url={`${window.location.origin}/ofertas-de-empleo/${job?.slug}`}
                              title={job?.title}
                              separator=':: '
                              className='Demo__some-network__share-button'
                            >
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                          </div>
                          <div>
                            <LinkedinShareButton
                              url={`${window.location.origin}/ofertas-de-empleo/${job?.slug}`}
                              className='Demo__some-network__share-button'
                            >
                              <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                          </div>
                          <div>
                            <EmailShareButton
                              url={`${window.location.origin}/ofertas-de-empleo/${job?.slug}`}
                              subject={job?.title}
                              body='body'
                              className='Demo__some-network__share-button'
                            >
                              <EmailIcon size={32} round />
                            </EmailShareButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-19'>
                <div className='text-center mb-12'>
                  <h3 className='fs-2hx text-dark mb-5'>Vacantes Recientes</h3>
                </div>
                <div className='row g-10'>
                  <div className='col'>
                    {recentJobs
                      .filter((e: any) => e.slug !== slug)
                      .map((job: any) => (
                        <CardJob key={job._id} job={job} />
                      ))}
                  </div>
                </div>
              </div>
              <div className='card mb-4 bg-light text-center'>
                <h3 className='card-title mt-5'>
                  <i className='fas fa-share-alt me-2 fs-4'></i>Compartir
                </h3>
                <div className='card-body py-12 d-flex justify-content-around'>
                  <a
                    onClick={() => {
                      share('facebook', job.title)
                    }}
                    target='_blank'
                    className='mx-4'
                  >
                    <img
                      src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
                      className='h-30px my-2'
                      alt=''
                    />
                  </a>
                  <a
                    onClick={() => {
                      share('email', job.title)
                    }}
                    className='mx-4'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com002.svg'
                      className='svg-icon svg-icon-3x svg-icon-primary'
                    />
                  </a>
                  <a
                    onClick={() => {
                      share('linkedin', job.title)
                    }}
                    className='mx-4'
                  >
                    <img
                      src={toAbsoluteUrl('/media/svg/brand-logos/linkedin-2.svg')}
                      className='h-30px my-2'
                      alt=''
                    />
                  </a>
                  <a
                    onClick={() => {
                      share('twitter', job.title)
                    }}
                    className='mx-4'
                  >
                    <img
                      src={toAbsoluteUrl('/media/svg/brand-logos/twitter.svg')}
                      className='h-30px my-2'
                      alt=''
                    />
                  </a>
                  <a
                    onClick={() => {
                      share('whatsapp', job.title)
                    }}
                    className='mx-4'
                  >
                    <img
                      src={toAbsoluteUrl('/media/svg/social-logos/whatsapp.svg')}
                      className='h-30px my-2'
                      alt=''
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <form
            className='form mb-15'
            method='post'
            id='kt_contact_form'
            onSubmit={formik.handleSubmit}
          >
            {/*begin::Input group*/}
            <div className='row mb-5'>
              {/*begin::Col*/}
              <div className='col-md-12 fv-row'>
                {/*begin::Label*/}
                <label className='fs-5 fw-bold mb-2 required'>Nombre</label>
                {/*end::Label*/}
                {/*begin::Input*/}
                <input
                  type='text'
                  placeholder='Nombre'
                  autoComplete='off'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.name && formik.errors.name,
                    },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span style={{color: 'red'}}>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
                {/*end::Input*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className='d-flex flex-column mb-10 fv-row'>
              <label className='fs-6 fw-bold mb-2 required'>Mensaje</label>
              <textarea
                rows={6}
                {...formik.getFieldProps('message')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.message && formik.errors.message},
                  {
                    'is-valid': formik.touched.message && !formik.errors.message,
                  }
                )}
              />
              {formik.touched.message && formik.errors.message && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span style={{color: 'red'}}>{formik.errors.message}</span>
                  </div>
                </div>
              )}
            </div>
            {/*end::Input group*/}
            <div className='d-flex flex-column mb-10 fv-row'>
              <label className='fs-6 fw-bold mb-2 required'>Adjunta tu CV</label>
              <input
                type='file'
                accept='application/pdf'
                onChange={(event: any) => {
                  var fReader = new FileReader()
                  fReader.readAsDataURL(event?.currentTarget?.files[0])
                  fReader.onloadend = function (event: any) {
                    formik.setFieldValue('attachment', event?.target?.result)
                  }
                }}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.attachment && formik.errors.attachment,
                  },
                  {
                    'is-valid': formik.touched.attachment && !formik.errors.attachment,
                  }
                )}
              />
              {formik.touched.attachment && formik.errors.attachment && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span style={{color: 'red'}}>{formik.errors.attachment}</span>
                  </div>
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              formik.submitForm()
            }}
          >
            Enviar solicitud
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
