import React from 'react'
import {Link} from 'react-router-dom'

export const AcceptTerms = () => {
  const PUBLIC_URL = window.location.origin.toString()
  return (
    <>
      <h1 className='text-center'>Términos y Condiciones de Uso</h1>
      <p style={{textAlign: 'justify'}}>
        Este {PUBLIC_URL} garantiza que la información personal que usted envía cuenta con la
        seguridad necesaria. Los datos ingresados por usuario o en el caso de requerir una
        validación no serán entregados a terceros, salvo que deba ser revelada en cumplimiento a una
        orden judicial o requerimientos legales.
      </p>
      <p style={{textAlign: 'justify'}}>
        La suscripción a boletines de correos electrónicos publicitarios es voluntaria y podría ser
        seleccionada al momento de crear su cuenta.
      </p>

      <p style={{textAlign: 'justify'}}>
        Tu Empleo Ideal reserva los derechos de cambiar o de modificar estos términos sin previo
        aviso.
      </p>

      <p style={{textAlign: 'justify'}}>
        El usuario puede elegir y cambiar la clave para su acceso de administración de la cuenta en
        cualquier momento, en caso de que se haya registrado y que sea necesario. {PUBLIC_URL} no
        asume la responsabilidad en caso de que entregue dicha clave a terceros.
      </p>

      <p style={{textAlign: 'justify'}}>
        Todas las publicaciones que se lleven a cabo por medio de este sitio web, están sujetas a un
        proceso de confirmación y verificación, el cual podría incluir la verificación del RNC y
        existencia de la empresa, validación de la forma de pago, validación de la factura (en caso
        de existir) y el cumplimiento de las condiciones requeridas por el medio de pago
        seleccionado. En algunos casos puede que se requiera una verificación por medio de correo
        electrónico.
      </p>
      <Link to='/auth/registration'>
        <button
          type='button'
          id='kt_login_signup_form_cancel_button'
          className='btn btn-lg btn-light-primary w-100 mb-5'
        >
          Atras
        </button>
      </Link>
    </>
  )
}
