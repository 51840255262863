/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import axios from 'axios'
import {isContentEmpty} from 'app/shared/utils/utils'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {message, Image} from 'antd'
import {
  BlogControllerServiceProxy,
  CreateBlogDto,
  UpdateBlogDto,
  Response,
} from 'app/services/service-proxies/service-proxies'
import {useNavigate, useParams} from 'react-router-dom'
import {UserModel} from 'app/modules/auth/models/UserModel'
import {RootState} from 'setup'
import {shallowEqual, useSelector} from 'react-redux'
import {APP_ROLES} from 'setup/app-constants'
import {useDropzone} from 'react-dropzone'
import {fileToBase64, niceBytes} from '../../shared/utils/utils'
import {Editor} from '@tinymce/tinymce-react'

const blogClient = new BlogControllerServiceProxy(undefined, axios)

export interface ICreatePost {
  _id: string | undefined
  title: string
  description: string
  author: string
  body: string
  image: string
}

const registrationSchema = Yup.object().shape({
  title: Yup.string()
    .min(6, 'Minimo 6 caracteres')
    .max(100, 'Maximo 50 caracteres')
    .required('El titulo es requerido'),
  description: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(500, 'Maximo 500 caracteres')
    .required('La descripcion es requerida'),
  author: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(100, 'Maximo 50 caracteres')
    .required('El autor es requerido'),
  body: Yup.string().required('El contenido es requerido'),
  image: Yup.string().required('La imagen es requerida'),
})

export const CreateBlogPage = () => {
  const [blog, setBlog] = React.useState<any>({})
  const params = useParams()
  const slug = params.slug!
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const navigate = useNavigate()
  const editorRef = React.useRef<any>(null)
  const [showMainImage, setShowMainImage] = React.useState<Boolean>(false)
  const onEditorStateChange = (value: string) => {
    if (isContentEmpty(value)) {
      formik.setValues({...formik.values, body: ''})
      return
    }
    formik.setValues({...formik.values, body: value})
  }

  React.useEffect(() => {
    if (slug) {
      getBlogDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  React.useEffect(() => {
    formik.setValues({
      ...formik.values,
      title: blog.title,
      description: blog.description,
      author: blog.author,
      body: blog.body,
      image: blog.image,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blog])

  const getBlogDetail = () => {
    blogClient.findBySlug(slug).then((blog: Response) => {
      setBlog(blog.data)
      if (blog.data.image?.length > 0) {
        if (blog.data.image) {
          setShowMainImage(true)
          formik.setFieldValue('image', blog.data.image)
        }
      }
    })
  }

  const update = (input: ICreatePost) => {
    let payload: UpdateBlogDto = new UpdateBlogDto()
    payload.init(input)
    payload._id = blog._id
    payload.published = false
    blogClient.update(payload._id, payload).then(() => {
      message.success({
        message: `Notification`,
        description: 'Post actualizado con exito!!!',
        placement: 'topRight',
      })
      navigate('/consejos-busqueda-empleo')
    })
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      author: '',
      body: '',
      image: '',
    } as ICreatePost,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      const content = editorRef.current.getContent()
      let data: CreateBlogDto = new CreateBlogDto()
      data.init(values)
      data.body = content
      if (blog._id) {
        update(values)
      } else {
        blogClient
          .create(data)
          .then(() => {
            message.success({
              message: `Notification`,
              description: 'Post creado con exito!!!',
              placement: 'topRight',
            })
            navigate('/consejos-busqueda-empleo')
          })
          .catch((err: any) => {
            setSubmitting(false)
            setStatus(err.message)
          })
      }
    },
  })

  const onDrop = React.useCallback(async (files) => {
    const base64 = await fileToBase64(files[0])
    formik.setFieldValue('image', base64)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const remove = (file: any) => {
    formik.setFieldValue('image', '')
    acceptedFiles.splice(file, 1)
  }

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    onDrop,
    maxSize: 10000000,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
  })

  const files = !showMainImage ? (
    acceptedFiles.map((file: any) => (
      <div className='card bg-warning bg-opacity-25' key={file.path}>
        <div className='card-body d-flex justify-content-between'>
          <span>
            {file.path} - {niceBytes(file.size)} bytes
          </span>
          <span onClick={() => remove(file)}>
            <i
              className='fa fa-times-circle text-danger fs-3'
              style={{cursor: 'pointer'}}
              aria-hidden='true'
            ></i>
          </span>
        </div>
      </div>
    ))
  ) : (
    <div>
      <Image width={200} src={blog.image} />
      <button
        onClick={(e) => {
          e.preventDefault()
          setShowMainImage(false)
        }}
        className='btn btn-sm btn-danger fw-bolder btn-active-light-primary ms-2'
      >
        Remplazar
      </button>
    </div>
  )

  return (
    <div className='content flex-row-fluid'>
      <div className='row'>
        <div className={`card card-xl-stretch mb-xl-8`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Nuevo Blog</span>
            </h3>
            <div className='card-toolbar'>
              <a onClick={() => navigate(-1)} className='btn btn-light float-end me-2'>
                Atras
              </a>
              {isAuthorized?.roles?.includes(APP_ROLES.ADMIN) && (
                <a onClick={() => formik.handleSubmit()} className='btn btn-primary'>
                  <i className='fas fa-save me-2'></i>
                  Guardar
                </a>
              )}
            </div>
          </div>
          <div className='card-body py-3'>
            <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate>
              <div className='fv-row mb-7'>
                <label className='d-flex align-items-center form-label mb-5 required'>Titulo</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('title')}
                  className={clsx(
                    'form-control form-control-lg',
                    {
                      'is-invalid': formik.touched.title && formik.errors.title,
                    },
                    {
                      'is-valid': formik.touched.title && !formik.errors.title,
                    }
                  )}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='text-danger mt-2'>{formik.errors.title}</div>
                )}
              </div>
              <div className='fv-row mb-7'>
                <label className='d-flex align-items-center form-label mb-5 required'>Autor</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('author')}
                  className={clsx(
                    'form-control form-control-lg',
                    {
                      'is-invalid': formik.touched.author && formik.errors.author,
                    },
                    {
                      'is-valid': formik.touched.author && !formik.errors.author,
                    }
                  )}
                />
                {formik.touched.author && formik.errors.author && (
                  <div className='text-danger mt-2'>{formik.errors.author}</div>
                )}
              </div>
              <div className='fv-row mb-7'>
                <label className='d-flex align-items-center form-label mb-5 required'>
                  Descripcion
                </label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('description')}
                  className={clsx(
                    'form-control form-control-lg',
                    {
                      'is-invalid': formik.touched.description && formik.errors.description,
                    },
                    {
                      'is-valid': formik.touched.description && !formik.errors.description,
                    }
                  )}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className='text-danger mt-2'>{formik.errors.description}</div>
                )}
              </div>
              <div className='fv-row mb-7'>
                <label className='class="form-label fw-bolder text-dark fs-6 required'>
                  Imagen principal (.jpg, .png)
                </label>
                <section className='mt-2'>
                  {acceptedFiles.length === 0 && !showMainImage ? (
                    <div {...getRootProps({className: 'dropzone'})}>
                      <input {...getInputProps()} />
                      <p>
                        Arrastre y suelte la imagen aquí, o haga clic para seleccionar la imagen.
                      </p>
                    </div>
                  ) : (
                    <aside>
                      <div>{files}</div>
                    </aside>
                  )}
                </section>
                {formik.touched.image && formik.errors.image && (
                  <div className='text-danger mb-2'>{formik.errors.image}</div>
                )}
              </div>
              <label className='d-flex align-items-center form-label mb-5 required'>
                Contenido
              </label>
              {formik.touched.body && formik.errors.body && (
                <div className='text-danger mb-2'>{formik.errors.body}</div>
              )}
              <div>
                <Editor
                  value={formik.values.body}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(newText) => onEditorStateChange(newText)}
                  init={{
                    height: 500,
                    plugins: [
                      'image',
                      'advlist',
                      'link',
                      'charmap',
                      'emoticons',
                      'fullscreen',
                      'lists',
                      'table',
                      'media',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | numlist bullist' +
                      'removeformat | emoticons | link | charmap | fullscreen | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
