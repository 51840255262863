// import { FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
// import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { UserPage } from '../pages/users/UserPage'
import { CategoryPage } from 'app/pages/categories/CategoryPage'
import { LocationPage } from 'app/pages/locations/LocationPage'
import { JobsManagement } from 'app/pages/jobs/JobsManagement'
// import { ViewJob } from 'app/pages/jobs/ViewJob'
import { FaqsManagement } from 'app/pages/faqs/FaqsManagement'
import { BlogWrapper } from 'app/pages/blog/BlogWrapper'
import { ServicesPage } from 'app/pages/services/ServicesPage'
import ProfilePage from 'app/pages/profile/ProfilePage'
import { LinkWrapper } from 'app/pages/links/LinksWrapper'
import { JobWrapper } from 'app/pages/jobs/JobWrapper'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/' />} />
        <Route path='/home' element={<DashboardWrapper />} />
        <Route path='/admin/users' element={<UserPage />} />
        <Route path='/admin/categories' element={<CategoryPage />} />
        <Route path='/admin/locations' element={<LocationPage />} />
        <Route path='/admin/jobManagement' element={<JobsManagement />} />
        <Route path='/admin/links/*' element={<LinkWrapper />} />
        <Route path='/faqs' element={<FaqsManagement />} />
        <Route path='consejos-busqueda-empleo/*' element={<BlogWrapper />} />
        <Route path='ofertas-de-empleo/*' element={<JobWrapper />} />
        <Route path='services/*' element={<ServicesPage />} />
        <Route path='user/profile/*' element={<ProfilePage />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

// const SuspensedView: FC = ({ children }) => {
//   TopBarProgress.config({
//     barThickness: 3,
//     shadowBlur: 5,
//   })
//   return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export { PrivateRoutes }
