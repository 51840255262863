import React from 'react'
import { MenuItem } from './MenuItem'
import { useIntl } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from 'setup'
import { UserModel } from 'app/modules/auth/models/UserModel'
import { APP_ROLES } from 'setup/app-constants'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { showModal } from 'app/modules/jobs/actions'
import { useNavigate } from 'react-router-dom'

export function MenuInner() {
  const intl = useIntl()
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleShowModal = () => {
    if (!isAuthorized) {
      navigate('/auth/login')
    } else {
      dispatch(showModal());
    }
  };

  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/' />
      <MenuItem title='Ofertas de Trabajo' to='/ofertas-de-empleo' />
      <MenuItem title='Consejos para la Búsqueda de Empleo' to='/consejos-busqueda-empleo' />
      <MenuItem title='Servicios' to='/services' />
      <MenuItem title='Preguntas Frecuentes' to='/faqs' />
      {isAuthorized ? <MenuItem title='Publicar Empleo' callback={handleShowModal} /> : <MenuItem title='Publicar Empleo' to='/auth/login' />}
      {isAuthorized?.roles?.includes(APP_ROLES.ADMIN) && <MenuInnerWithSub title='Configuración' to='configurations' menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}>
        <MenuItem title='Usuarios' to='/admin/users' hasBullet />
        <MenuItem title='Categorias' to='/admin/categories' hasBullet />
        <MenuItem title='Locaciones' to='/admin/locations' hasBullet />
        <MenuItem title='Enlaces' to='/admin/links' hasBullet />
      </MenuInnerWithSub>}
    </>
  )
}
