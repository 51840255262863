import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'
import {loginWithGoogle} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  LoginWithGoogle: '[LoginWithGoogle] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const {data} = action.payload as any
        const accessToken = data.accessToken
        return {accessToken, user: data.user}
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        const user = action.payload?.user
        return {accessToken, user}
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (data: any) => ({type: actionTypes.Login, payload: {data}}),
  loginWithGoogle: () => ({type: actionTypes.LoginWithGoogle}),
  register: (data: any) => {
    return {
      type: actionTypes.Register,
      payload: {accessToken: data.accessToken, user: data.user},
    }
  },
  logout: () => ({type: actionTypes.Logout}),
  requestUser: (data: any) => {
    return {
      type: actionTypes.UserRequested,
      payload: {accessToken: data.accessToken, user: data.user},
    }
  },
  fulfillUser: (user: UserModel) => {
    return {type: actionTypes.UserLoaded, payload: {user}}
  },
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga({payload: {data}}: any) {
    yield put(actions.requestUser(data))
  })

  yield takeLatest(actionTypes.LoginWithGoogle, loginWithGoogle)

  yield takeLatest(actionTypes.Register, function* registerSaga({payload}: any) {
    yield put(actions.requestUser(payload))
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested({payload}: any) {
    yield put(actions.fulfillUser(payload.user))
  })
}
