/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import dayjs from 'dayjs'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {CommentCount, DiscussionEmbed} from 'disqus-react'
import {BlogControllerServiceProxy, Response} from 'app/services/service-proxies/service-proxies'
import axios from 'axios'
import {shallowEqual, useSelector} from 'react-redux'
import {parse} from 'node-html-parser'
import {RootState} from 'setup'
import {UserModel} from 'app/modules/auth/models/UserModel'
import {APP_ROLES} from 'setup/app-constants'
import {isMobileDevice} from '_metronic/assets/ts/_utils/DomHelpers'
import clsx from 'clsx'
import './BlogPage.scss'

const blogClient = new BlogControllerServiceProxy(undefined, axios)

export const BlogDetailPage = () => {
  const fallbackImage = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'
  const user = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const navigate = useNavigate()
  const params = useParams()
  const slug = params.slug!
  const [blog, setBlog] = React.useState<any>({})
  const format = (date: Date) => {
    return dayjs(date).format('MMMM DD, YYYY')
  }

  const getImageSource = (html: string) => {
    if (html) {
      const result = parse(html)?.querySelector('img')?.getAttribute('src')
      return result ? result : 'https://fakeimg.pl/640x360'
    }
    return ''
  }

  React.useEffect(() => {
    getBlogDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, user])

  const getBlogDetail = () => {
    blogClient.findBySlug(slug).then((blog: Response) => {
      setBlog(blog.data)
      if (!user && blog.data?.hasOwnProperty('_id')) {
        blogClient.addView(blog.data?._id)
      }
    })
  }

  const goToEditPage = () => {
    navigate(`/consejos-busqueda-empleo/create/${blog.slug}`)
  }

  return (
    <>
      <div className='card card-xl-stretch mb-xl-8 container'>
        <div className='card-header border-0 pt-5 container'>
          <h3 className='card-title align-items-start flex-column'>
            <ol className='breadcrumb text-muted fs-6 fw-semibold'>
              <li className='breadcrumb-item'>
                <Link to={'/'}>Inicio</Link>
              </li>
              <li className='breadcrumb-item'>
                <Link to={'/consejos-busqueda-empleo'}>Consejos para la Búsqueda de Empleo</Link>
              </li>
              <li className='breadcrumb-item text-muted'>{blog?.title}</li>
            </ol>
          </h3>
          <div className='card-toolbar'>
            {user?.roles?.includes(APP_ROLES.ADMIN) && (
              <a onClick={goToEditPage} className='btn btn-primary'>
                Editar
              </a>
            )}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='d-flex justify-content-start justify-content-lg-center'>
            <div className='row w-100 w-md-50'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12 d-flex my-5'>
                    <h1 className='blog-title'>{blog?.title}</h1>
                  </div>
                </div>
                <div className='row mt-5'>
                  <div className='row'>
                    <div className='col-12 d-flex justify-content-start justify-content-lg-center my-5'>
                      <img
                        className={clsx('img-fluid rounded-3', {'blog-img': !isMobileDevice()})}
                        src={blog.image ? blog.image : getImageSource(blog.body)}
                        onError={(e) => (e.currentTarget.src = fallbackImage)}
                      />
                    </div>
                  </div>
                  <div className='mb-5'>
                    <span className='me-3 fs-5 fw-normal fw-normal text-gray-500'>
                      {format(blog?.createdAt)}
                    </span>
                    <span className='fs-5 fw-normal fw-normal text-gray-500'>
                      <i className='fa fa-user-circle me-2' aria-hidden='true'></i>
                      {blog?.author}
                    </span>
                    <span className='fs-5 fw-normal fw-normal text-gray-500'>
                      <i className='fa fa-eye ms-3 me-2' aria-hidden='true'></i>
                      {blog?.viewCount}
                    </span>
                    <span className='fs-5 fw-normal fw-normal text-gray-500'>
                      <i className='far fa-comment ms-3 me-2'></i>
                      <CommentCount
                        shortname='vacantestei'
                        config={{
                          url: `${window.location.href}/${blog?._id}`,
                          identifier: `${blog?._id}_${blog?.title}`,
                          title: blog?.title,
                        }}
                      >
                        Comentarios
                      </CommentCount>
                    </span>
                  </div>
                  <div
                    className='col-12 blog-content mt-5'
                    dangerouslySetInnerHTML={{__html: blog?.body?.replace(/<img.*?\/>/, '')}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-md-center'>
        <div className='row w-100 w-md-50 my-5'>
          <DiscussionEmbed
            shortname='vacantestei'
            config={{
              url: `${window.location.href}/${blog?._id}`,
              identifier: `${blog?._id}_${blog?.title}`,
              title: blog?.title,
              language: 'Es-es',
            }}
          />
        </div>
      </div>
    </>
  )
}
